import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { kebabCase } from 'lodash'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

import Container from '@material-ui/core/Container'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  date,
  title,
  catchtext,
  helmet,
  url,
  next,
  prev,
}) => {
  const PostContent = contentComponent || Content
  return (
    <>
    <section className="ContentDetail">
      {helmet || ''}
      <Container>
      <div className="ContentDetail-header">
        <div className="ContentDetail-header-inner">
            <p className="ContentDetail-header-category">
                <span>{tags[0]}</span>
            </p>
          <p className="ContentDetail-header-date">{date}</p>
        </div>
        <h1>{title}</h1>
      </div>
      <div className="ContentDetail-main">
        <div className="ContentDetail-content">
          <div className="ContentDetail-content-topics">
            <h3><span>課題</span>{catchtext}</h3>
          </div>
          <div className="ContentDetail-content-idea">
            <h3><span className="Icon-idea">IDEA</span>{description}</h3>
          </div>
        </div>

        <div className="ContentDetailPostContent">
          <PostContent className="ContentDetailPostContent-markdown" content={content} />

          <div className="ContentDetailPostContent-tags">
          <h5>Tags</h5>
          {tags && tags.length ? (
            <Grid container spacing={1}>
            {tags.map(tag => (
              <Grid item key={tag + `tag`}>
                <Button variant="outlined" href={`/tags/${kebabCase(tag)}/`} size="small" className="ButtonPrimary">
                  {tag}
                </Button>
              </Grid>
            ))}
            </Grid>
          ) : null}
          </div>

          <div className="ContentDetailPostContent-share">
            <h5>Share</h5>
            <Grid container spacing={1}>
              <Grid item>
                <Button target="_blank" variant="outlined" href={`http://b.hatena.ne.jp/add?mode=confirm&amp;url=https://idea-maps.com${url}&amp;title=${title}-アイデアマップ`} size="small" className="ButtonPrimary">
                  はてな
                </Button>
              </Grid>
              <Grid item>
                <Button target="_blank" variant="outlined" href={`https://twitter.com/intent/tweet?text=${title}${catchtext}https://idea-maps.com${url}`} size="small" className="ButtonPrimary">
                  Twitter
                </Button>
              </Grid>
              <Grid item>
                <Button target="_blank" variant="outlined" href={`http://www.facebook.com/share.php?u=https://idea-maps.com${url}`} size="small" className="ButtonPrimary">
                  Facebook
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      <div>
        <ul className="PageNaviList">
          {(prev) && (prev.frontmatter.templateKey === 'idea-post')?(
          <>
            <li className="PageNaviList-item PageNaviList-prev">
              <Link to={prev.fields.slug}>
                <span>
                  {prev.frontmatter.title}
                </span>
              </Link>
            </li>
          </>
           ) : <li></li>}
          {(next) && (next.frontmatter.templateKey == 'idea-post')?(
          <>
            <li className="PageNaviList-item PageNaviList-next">
              <Link to={next.fields.slug}>
                <span>
                  {next.frontmatter.title}
                </span>
              </Link>
            </li>
          </>
          ) : <li></li>}
        </ul>
      </div>
      </Container>
    </section>
    </>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data;
  const {next, prev} = pageContext;
  return (
    <Layout>
      <BlogPostTemplate
        helmet={
          <Helmet titleTemplate="%s | アイデアマップ">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@idea_maps" />
            <meta property="og:url" content={`https://idea-maps.com${post.fields.slug}`} />
            <meta property="og:title" content={`${post.frontmatter.title}`} />
            <meta property="og:description" content={`${post.frontmatter.description}`} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        catchtext={post.frontmatter.catch}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        url={post.fields.slug}

        next={next}
        prev={prev}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}
export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    allMarkdownRemark(
      filter: {
        frontmatter: {
        templateKey:  { eq: "idea-post" }
      } }
    ){
      edges {
        next {
          frontmatter {
            title
            path
            templateKey
          }
        }
        previous {
          frontmatter {
            title
            path
            templateKey
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      html
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        title
        description
        tags
        catch
      }
    }
  }
`